import * as React from "react";
import Box from "@mui/system/Box";
import { useTheme } from "@mui/system";
import { Link } from "../elements/link";

export const Category = ({
  category,
  isHovered,
  setPreview,
  setIsHovered,
  setMenuDropdown,
}) => {
  const theme = useTheme();
  // const [isHovered, setIsHovered] = React.useState(false);

  const [activeCategory, setActiveCategory] = React.useState(false);

  return (
    <Box
      sx={{
        breakInside: "avoid",
        mb: 4,
        "&:last-child": {
          mb: 0,
        },
      }}
    >
      <Box
        sx={{
          textTransform: "uppercase",
          fontSize: 13,
          letterSpacing: "-0.02em",
          opacity: isHovered && !activeCategory ? 0.2 : 1,
        }}
      >
        {category.title}
      </Box>

      <Box
        component="ul"
        sx={{
          listStyleType: "none",
          margin: 0,
          padding: 0,
          zIndex: 10,
          [theme.breakpoints.up("md")]: {
            flex: 1,
            // columnFill: "auto",
            // columnCount: 3,
            // height: "1px",
            // minHeight: rosterMinHeight ? `${rosterMinHeight}px` : 0,
          },
        }}
      >
        {category.entries &&
          category.entries.map((entry, index) => (
            <Box
              key={index}
              // ref={listItemRef}
              component="li"
              sx={{
                fontSize: { xs: "var(--font-size--large)" },
              }}
            >
              <Link
                to={`/${entry.talent?.slug?.current}`}
                noUnderline
                secondary={isHovered ? true : false}
                onMouseEnter={() => {
                  setIsHovered(true);
                  setActiveCategory(true);
                  setPreview(entry.previewMedia);
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                  setActiveCategory(false);
                  // setPreview(null);
                }}
                onClick={() => {
                  setMenuDropdown((prevState) => {
                    return {
                      ...prevState,
                      state: {
                        isOpen: false,
                        key: null,
                      },
                    };
                  });
                }}
                style={{ width: "fit-content" }}
              >
                {entry.talent?.title}
              </Link>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
