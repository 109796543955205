import styled from "@mui/system/styled";

export const NewsletterSignup = styled("div")(
  ({ theme, isOpen }) => `
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--color--primary);
  padding: 12px 16px;
  transition: transform 0.25s cubic-bezier(0.42, 0, 0, 1), visibility 0.25s;
  visibility: ${isOpen ? "visible" : "hidden"};
  transform: translateY(${isOpen ? "0" : "100%"});

  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0.75rem 1rem;
  }
`
);

export const Inner = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: 4rem;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // gap: 1rem;

  // align-items: flex-start;
  // justify-content: flex-start;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    gap: 2rem;
    justify-content: space-between;
  }
`
);

export const FormWrapper = styled("div")(
  ({}) => `
  position: relative;
`
);

export const Message = styled("div")(
  ({ state }) => `
    transition: opacity 0.5s ease-in-out 0.5s;
    opacity: ${state ? 1 : 0};
    position: absolute;
    pointer-events: none;
`
);

export const Form = styled("form")(
  ({ state }) => `
  display: flex;
  flex: 1;

  transition: opacity 0.5s ease-in-out;
  opacity: ${state ? 0 : 1};
  pointer-events: ${state ? "none" : "auto"};
`
);

export const InputWrapper = styled("div")(
  ({ theme }) => `
  position: relative;
  margin-right: 1rem;
  margin-left: 0.25rem;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
    height: 1px;
    width: 100%;
    background-color: var(--color--secondary);
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    &::after {
      height: 2px;
    }
  }
`
);

export const Input = styled("input")(
  ({ theme }) => `
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;

  // min-width: 300px;
  font-size: inherit;
  font-family: inherit; 
  position: relative;

  -webkit-text-fill-color: var(--color-secondary) !important;
  -webkit-background-clip: text !important;
  background-clip:  text !important;

  &:focus {
    outline: none;
  }
    

  &:autofill,
  &:autofill:hover,
  &:autofill:focus
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    filter: none; /* u need this for firefox */
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 40rem var(--color--primary) inset;
    background-color: var(--color--primary);
    font-size: inherit; /* Use the input's font size */
    font-family: inherit; /
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    min-width: 300px;
  }
`
);

export const Button = styled("button")(
  ({}) => `
  appearance: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-decoration: underline;
`
);
