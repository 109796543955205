import * as React from "react";
import * as S from "./styles";
import Box from "@mui/system/Box";

import { TextButton } from "../elements/button";

import { useSiteSettings } from "../../hooks/useSiteSettings";

import addToMailchimp from "gatsby-plugin-mailchimp";
import { Stack } from "@mui/system";

export const NewsletterSignup = ({ isOpen, setIsOpen }) => {
  const { newsletter } = useSiteSettings();
  const [email, setEmail] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    if (!state) return;

    const { result, msg } = state;

    console.log(state);

    if (result === "error") {
      if (msg.includes("already") && msg.includes("subscribed")) {
        setMessage("You're already subscribed");
      } else if (msg.includes("valid")) {
        setMessage("Email seems to be invalid");
      } else {
        setMessage(msg);
      }

      setTimeout(() => {
        setMessage(null);
        setState(null);
      }, 3000);
    } else if (result === "success") {
      setMessage("Thank you for subscribing!");
    }
  }, [state]);

  if (!newsletter || !newsletter.enableNewsletterPopup) {
    return null;
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    addToMailchimp(email)
      .then((data) => {
        setState(data);
      })
      .catch(() => {
        setState(true);
        setMessage("There was an error, try again later");
      });
  };

  // console.log(state);

  return (
    <S.NewsletterSignup isOpen={isOpen}>
      <S.Inner>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent={{ sm: "space-between" }}
          flex={{ sm: 1 }}
          spacing={2}
        >
          <div>{newsletter.callToAction}</div>

          <S.FormWrapper>
            <S.Message state={state}>{message}</S.Message>

            <S.Form onSubmit={handleSubmit} state={state}>
              <label>Email</label>
              <S.InputWrapper>
                <S.Input
                  type="text"
                  onChange={(e) => setEmail(e.target?.value)}
                />
              </S.InputWrapper>
              <TextButton
                secondary
                disabled={email?.length === 0 || processing}
              >
                Sign up
              </TextButton>
            </S.Form>
          </S.FormWrapper>

          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            <TextButton onClick={handleClose}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "1.15rem",
                }}
              >
                <svg
                  style={{ height: "100%" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M19.55 1.61 18.33.4l-8.3 8.29L1.73.4.52 1.62l8.29 8.29-8.29 8.3 1.22 1.22 8.29-8.3 8.3 8.3 1.22-1.22-8.3-8.3z" />
                </svg>
              </Box>
            </TextButton>
          </Box>
        </Stack>

        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
          }}
        >
          <TextButton onClick={handleClose}>
            <Box
              sx={{ display: "flex", alignItems: "center", height: "1.15rem" }}
            >
              <svg
                style={{ width: "26px", height: "26px" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M19.55 1.61 18.33.4l-8.3 8.29L1.73.4.52 1.62l8.29 8.29-8.29 8.3 1.22 1.22 8.29-8.3 8.3 8.3 1.22-1.22-8.3-8.3z" />
              </svg>
            </Box>
          </TextButton>
        </Box>
      </S.Inner>
    </S.NewsletterSignup>
  );
};
