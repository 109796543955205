import * as React from "react";
import Box from "@mui/system/Box";
import { Stack } from "@mui/system";

import { ExternalLink, Link } from "../../elements/link";
import { Wordmark } from "../wordmark";

import { useSiteSettings } from "../../../hooks/useSiteSettings";
import { TextButton } from "../../elements/button";
import { CustomPortableText } from "../../customPortableText";

export const Footer = ({ newsletterIsOpen, setNewsletterIsOpen }) => {
  const siteSettings = useSiteSettings();
  const { offices, links } = siteSettings;

  return (
    <Stack
      sx={{
        mt: "auto",
        mb: { xs: 12, md: 6 },
        pt: 4,
        px: 2,
        pb: 1.5,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Link to="/" style={{ display: "block", width: "100%" }}>
        <Wordmark />
      </Link>
      <Stack sx={{ mt: 1, py: 0, px: 2 }}>
        <Stack
          // direction="row"
          direction={{ lg: "row" }}
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            columnGap: 1,
            rowGap: { xs: 2, md: 0 },
          }}
        >
          {offices.map((office) => (
            <Stack
              key={office._key}
              direction={{ lg: "row" }}
              sx={{ textAlign: { xs: "center" }, columnGap: 1 }}
            >
              {(office.title || office.address) && (
                <Box sx={{ display: { xs: "block", md: "flex" } }}>
                  {office.title && (
                    <Box
                      component="div"
                      sx={{
                        display: { xs: "block", md: "inline" },
                        // textTransform: "uppercase",
                        textAlign: "center",
                        whiteSpace: "pre",
                      }}
                    >
                      {office.title}
                      {", "}
                    </Box>
                  )}
                  {office.address}
                </Box>
              )}
              {office.vat && <Box sx={{ display: "inline" }}>{office.vat}</Box>}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{
          mt: { xs: 2, lg: 0 },
          columnGap: 1,
          rowGap: 1,
          flexWrap: "wrap",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        {links.map((link) => {
          if (!link?._type) return null;

          if (link._type === "emailField") {
            return (
              <ExternalLink key={link._key} small href={`mailto:${link.value}`}>
                {link.label}
              </ExternalLink>
            );
          } else if (link._type === "link") {
            return (
              <ExternalLink
                key={link._key}
                small
                target="_blank"
                href={link.value}
              >
                {link.label}
              </ExternalLink>
            );
          } else if (link._type === "linkInternal") {
            return (
              <Link key={link._key} small to="/terms-and-conditions">
                {link.label}
              </Link>
            );
          } else if (link._type === "linkNewsletter") {
            return (
              <TextButton
                key={link._key}
                onClick={() => setNewsletterIsOpen(!newsletterIsOpen)}
              >
                {link.label}
              </TextButton>
            );
          } else if (link._type === "linkText") {
            return <CustomPortableText component="div" value={link.body} />;
          } else {
            return null;
          }
        })}
      </Stack>
    </Stack>
  );
};
