import * as React from "react";

import { PortableText } from "@portabletext/react";
import { Link, ExternalLink } from "../elements/link";

export const CustomPortableText = ({ component, value }) => {
  const components = {
    block: {
      normal: ({ children }) => {
        if (component === "div") {
          return <div>{children}</div>;
        }

        return <p>{children}</p>;
      },
    },
    marks: {
      link: ({ children, value }) => {
        return (
          <ExternalLink
            key={value._key}
            small
            target="_blank"
            href={value.value}
          >
            {children}
          </ExternalLink>
        );
      },
      linkInternal: ({ children, value }) => {
        console.log(value);

        return (
          <Link key={value._key} small to="/terms-and-conditions">
            {children}
          </Link>
        );
      },
      emailField: ({ children, value }) => {
        return (
          <ExternalLink key={value._key} small href={`mailto:${value.value}`}>
            {value.label}
          </ExternalLink>
        );
      },
    },
  };

  return <PortableText components={components} value={value} />;
};
