import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as S from "./styles";

import { useMenuDropdown } from "../../utils/MenuDropdownContext";
import { useMousePosition } from "../../hooks/useMousePosition";
import MuxPlayer from "@mux/mux-player-react/lazy";
import { VideoWithoutControls } from "../media/video";

export const Preview = ({ isHovered, preview }) => {
  const mousePosition = useMousePosition();
  const menuDropdown = useMenuDropdown();

  const transform = `translate(${mousePosition.x}px, ${mousePosition.y}px)`;

  if (!preview) return null;

  const imageData = getImage(preview?.asset);

  return (
    <S.Preview
      isHovered={isHovered}
      contentType={preview?.contentType}
      style={{ transform }}
    >
      <S.PreviewInner>
        {preview?.contentType === "video" && (
          <VideoPreview video={preview?.previewVideo} />
        )}

        {preview?.contentType === "image" && (
          <ImagePreview image={preview?.previewImage} />
        )}

        {preview && (
          <GatsbyImage
            // key={index}
            image={imageData}
            alt=""
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              // opacity: index == preview ? "1" : "0",
            }}
          />
        )}

        {/* {entries &&
          entries.map((entry, index) => {
            const imageData = getImage(entry.preview?.asset);
            if (!imageData) return null;

            return (
              <GatsbyImage
                key={index}
                image={imageData}
                alt=""
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  left: "0",
                  opacity: index == preview ? "1" : "0",
                }}
              />
            );
          })} */}
      </S.PreviewInner>
    </S.Preview>
  );
};

const ImagePreview = ({ image }) => {
  const imageData = getImage(image?.asset);
  if (!imageData) return;

  return (
    <GatsbyImage
      image={imageData}
      alt=""
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
      }}
    />
  );
};

const VideoPreview = ({ video }) => {
  if (!video) return;

  return <VideoWithoutControls data={video} />;
};
