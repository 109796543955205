import * as React from "react";
import { navigate, PageRenderer, Link } from "gatsby";
import { Global, css } from "@emotion/react";
import * as normalize from "normalize.css";
import { AnimatePresence, motion } from "framer-motion";

import { useElementSize } from "../hooks/useElementSize";
import { useLoader } from "../utils/LoaderContext";
import { StoreContextProvider } from "../hooks/useStore";
// import { useMenuDropdown } from '../utils/MenuDropdownContext'

import { ScrollRestoration } from "../components/global/scrollRestoration";
import { Cart } from "../components/cart";
import { Roster } from "../components/roster";
import { Filter } from "../components/filter";
import { Footer } from "../components/global/footer";
import { Header } from "../components/global/header";
import { HeaderShop } from "../components/global/headerShop";
import { MobileMenu } from "../components/global/mobileMenu";
import { Modal } from "../components/global/modal";
import { Box } from "@mui/system";
import { MenuDropdown } from "../components/menuDropdown/menuDropdown";
import { NewsletterSignup } from "../components/newsletterSignup";

const globals = css`
  :root {
    --color--primary: #ff3232;
    --color--secondary: #000;
    --color--tertiary: #fff;
    --padding--regular: 0.909rem;
    --font--default: Arial, Helvetica, sans-serif;
    --font--narrow: "Arial Narrow", Arial, Helvetica, sans-serif;
    --font-size--mobileMenu: 2rem;
    --font-size--regular: 13px;
    --font-size--large: 1.5rem;
    --font-size--xlarge: var(--font-size--large);

    @media (min-width: 768px) {
      --font-size--regular: 14px;
      --font-size--xlarge: calc(var(--font-size--large) * 2);
    }

    @media (min-width: 1024px) {
      --font-size--regular: 16px;
    }

    @media (min-width: 1350px) {
      --font-size--regular: 22px;
    }
  }

  ${normalize}

  html, body {
    overflow: hidden;
    overscroll-behavior: none;
  }

  html {
    box-sizing: border-box;
    font-family: var(--font--default);
    font-size: var(--font-size--regular);
    line-height: 1.1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: var(--color--primary); */
  }

  body {
    margin: 0;
    background-color: var(--color--tertiary);
    min-height: 100dvh;
    letter-spacing: -0.03rem;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    /* background: red; */
  }

  // Hide scroll bars on body/html to avoid double scrollbars
  html {
    scrollbar-width: none;
  }

  body {
    -ms-overflow-style: none;
  }

  body::-webkit-scrollbar {
    display: none;
  }
`;

type Props = {
  children?: React.ReactNode;
};

export const Layout = ({ children, location, pageContext }) => {
  if (location?.state?.modal) {
    return (
      <NoHeaderLayout location={location}>
        <PageRenderer location={{ pathname: location.state.previousUrlPath }} />
        <Cart />
        <Modal>{children}</Modal>
      </NoHeaderLayout>
    );
  }

  return (
    <DefaultLayout pageContext={pageContext} location={location}>
      {children}
    </DefaultLayout>
  );
};

export const ShopLayout = ({ children, location }) => {
  const [headerShopRef, { height }] = useElementSize();
  const [headerIsFaded, setHeaderFaded] = React.useState(true);

  return (
    <React.Fragment>
      <Global styles={globals} />
      {/* <StoreContextProvider> */}
      <Cart />
      <ScrollRestoration
        style={{ height: "100dvh", overflow: "scroll" }}
        setHeaderFaded={setHeaderFaded}
        headerHeight={height}
      >
        <HeaderShop headerIsFaded={headerIsFaded} />
        {children}
        <Footer />
      </ScrollRestoration>
      {/* </StoreContextProvider> */}
    </React.Fragment>
  );
};

const NoHeaderLayout = ({ children, location }) => {
  const [mobileMenuIsOpen, toggleMobileMenu] = React.useState(false);

  return (
    <React.Fragment>
      <Global styles={globals} />
      <MobileMenu
        mobileMenuIsOpen={mobileMenuIsOpen}
        toggleMobileMenu={toggleMobileMenu}
        location={location}
      />
      <Cart />
      {children}
    </React.Fragment>
  );
};

const DefaultLayout = ({ children, location, pageContext }) => {
  const [filterMenuIsOpen, toggleFilterMenu] = React.useState(false);
  const [mobileMenuIsOpen, toggleMobileMenu] = React.useState(false);
  const [headerIsFaded, setHeaderFaded] = React.useState(false);
  const [realHeight, setRealHeight] = React.useState(-100);
  const [newsletterIsOpen, setNewsletterIsOpen] = React.useState(true);
  const [headerRef, { height }] = useElementSize();
  const loader = useLoader();

  // Set height when it's been calculated in the DOM
  React.useEffect(() => {
    setRealHeight(height);
  }, [height]);

  // console.log(pageContext);

  return (
    <React.Fragment>
      <Global styles={globals} />
      <Cart />
      <Filter
        filterMenuIsOpen={filterMenuIsOpen}
        toggleFilterMenu={toggleFilterMenu}
        location={location}
      />
      <MenuDropdown
      // isOpen={ talentMenuIsOpen }
      // toggleMenu={ toggleTalentMenu }
      />
      <div ref={headerRef}>
        {/* {pageContext.layout === "shop" ? (
          <HeaderShop headerIsFaded={headerIsFaded} />
        ) : (
          <Header
            toggleFilterMenu={toggleFilterMenu}
            toggleMobileMenu={toggleMobileMenu}
            location={location}
            headerIsFaded={headerIsFaded}
          />
        )} */}

        <Header
          toggleFilterMenu={toggleFilterMenu}
          toggleMobileMenu={toggleMobileMenu}
          location={location}
          headerIsFaded={headerIsFaded}
          pageContext={pageContext}
        />
      </div>
      <MobileMenu
        mobileMenuIsOpen={mobileMenuIsOpen}
        toggleMobileMenu={toggleMobileMenu}
        location={location}
      />

      <div style={{ marginTop: `-${realHeight}px` }}>
        <AnimatePresence initial={false} mode="wait">
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -15 }}
            key={location.pathname}
            transition={{
              duration: 0.165,
            }}
          >
            <ScrollRestoration
              style={{
                height: "100dvh",
                overflow: "scroll",
                overflowX: "hidden",
              }}
              setHeaderFaded={setHeaderFaded}
              headerHeight={height}
            >
              <Box
                sx={{
                  paddingTop: `${height}px`,
                  backgroundColor: "var(--color--tertiary)",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: `calc(100dvh - ${height}px)`,
                }}
              >
                {children}
                <div
                  style={{
                    opacity: loader ? 0 : 1,
                    transition: "opacity 0.375s ease-in-out 0.2s",
                    mt: "auto",
                  }}
                >
                  <NewsletterSignup
                    isOpen={newsletterIsOpen}
                    setIsOpen={setNewsletterIsOpen}
                  />
                  <Footer
                    newsletterIsOpen={newsletterIsOpen}
                    setNewsletterIsOpen={setNewsletterIsOpen}
                  />
                </div>
              </Box>
            </ScrollRestoration>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* <div style={{ marginTop: `-${ realHeight }px` }}>
        <div
          className='canvas'
          style={{ 
            height: '100vh', 
            // height: '100dvh', 
            overflow: rosterIsOpen ? 'hidden' : 'scroll' 
          }}
        >
          <div 
            className='content'
            style={{ 
              // height: '100dvh',
              height: '100vh',
              transform: rosterIsOpen ? 'translateY(0)':`translateY(-100vh)`,
              transition: 'transform 0.25s cubic-bezier(0.42, 0, 0, 1)',
            }}
          >
            <div
              style={{
                height: '100%',
                overflow: 'hidden',
                backgroundColor: 'var(--color--primary)',
              }}
            >
              <div style={{ overflow: 'scroll' }}>
                <Roster toggleRosterMenu={ toggleRosterMenu } />
              </div>
            </div>

            <AnimatePresence initial={ false } mode="wait">
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -15 }}
                key={ location.pathname }
                transition={{
                  duration: 0.165,
                }}
              >
                <ScrollRestoration 
                  style={{ 
                    height: '100dvh',
                    overflow: rosterIsOpen ? 'hidden' : 'scroll',
                  }}
                  setHeaderFaded={ setHeaderFaded }
                  headerHeight={ height }
                >
                  <Box
                    sx={{
                      paddingTop: `${height}px`,
                      backgroundColor: 'var(--color--tertiary)',
                    }}
                  >
                    { children }
                    <Box
                      sx={{
                        opacity: loader ? 0 : 1,
                        transition: 'opacity 0.375s ease-in-out 0.2s'
                      }}
                    >
                      <Footer />
                    </Box>
                  </Box>
                </ScrollRestoration>
              </motion.div>
            </AnimatePresence> 
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};
