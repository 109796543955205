import * as React from "react";
import MuxPlayer from "@mux/mux-player-react/lazy";
import styled from "@mui/system/styled";
import "@mux/mux-video";
import {
  MediaController,
  MediaControlBar,
  MediaTimeRange,
  MediaTimeDisplay,
  MediaLoadingIndicator,
  MediaVolumeRange,
  MediaPlaybackRateButton,
  MediaPlayButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaMuteButton,
  MediaCaptionsButton,
  MediaAirplayButton,
  MediaPipButton,
  MediaFullscreenButton,
  MediaPosterImage,
} from "media-chrome/dist/react";

// import styled from '@emotion/styled'
// import * as S from './styles'

const StyledMuxPlayer = styled(MuxPlayer)`
  height: fit-content;
  --dialog: none;
  --loading-indicator: none;
  --media-object-fit: cover;
  --controls: none;
  line-height: 0;
`;

const StyledMuxPlayerWithControls = styled("div")(
  ({ theme, width, height }) => `
  height: fit-content;
  --dialog: none;
  --loading-indicator: none;
  --controls: none;
  --media-object-fit: cover;
  --media-background-color: var(--color--primary);
  --media-control-background: transparent;
  --media-control-hover-background: transparent;
  --media-font-family: var(--font--narrow);
  line-height: 0;

  display: flex;
  align-items: center;
  position: relative;
  // backgroundColor: isPlaying ? "transparent" : "var(--color--primary)",
  overflow: hidden;
  line-height: 0;
  aspect-ratio: ${width} / ${height};

  span[slot] {
    display: flex;
  }


  svg {
    width: 1rem;

    path {
      fill: var(--color--tertiary);
    }

    &.large {
      opacity: 0.5;
      width: 4rem;

      &:hover {
        opacity: 1;
      }

      @media (min-width: ${theme.breakpoints.values.md}px) {
        width: 2rem;
      }

    }
  }
`
);

const MuxVideo = styled("mux-video")``;

const Overlay = styled("div")(
  ({ theme, isOverlayVisible }) => `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  line-height: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem auto 2rem;
  grid-template-areas: "top" "center" "bottom";
  padding: 0.75rem 1rem;
  opacity: ${isOverlayVisible ? 1 : 0};
  transition: opacity 0.125s ease-in-out;

  svg {
    opacity: 0.5;
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0.5rem;
    }
`
);

const Center = styled("div")`
  grid-area: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bottom = styled("div")(
  ({ theme }) => `
  grid-area: bottom;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    gap: 0.25rem;
  }
`
);

const Button = styled("button")(
  ({ theme, large }) => `
  appearance: none;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  /* padding: 1rem; */

  svg {
    width: ${large ? "4rem" : "2rem"};
    padding: ${large ? "0.5rem" : "0"};
    transition: 0.125s ease-in-out;

    &:hover {
      opacity: 1;
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: ${large ? "2rem" : "1rem"};
    }
  }

  path {
    fill: var(--color--tertiary);
  }
`
);

export const Video = ({ data, enableVideoControls }) => {
  if (!data?.asset) return;

  if (enableVideoControls) {
    return <VideoWithControls data={data} />;
  }

  return <VideoWithoutControls data={data} />;
};

const VideoWithControls = ({ data }) => {
  const videoPlayer = React.useRef(null);
  const [isPlaying, setPlaying] = React.useState(false);

  const { asset } = data;
  if (!asset?.playbackId) return null;

  const { width, height } = getDimensions(asset?.data?.aspect_ratio);

  React.useEffect(() => {
    const player = videoPlayer.current;

    if (player) {
      player.addEventListener("playing", () => {
        setPlaying(true);
      });

      player.addEventListener("pause", () => {
        setPlaying(false);
      });
    }
  }, []);

  return (
    <StyledMuxPlayerWithControls width={width} height={height}>
      <MediaController
        // ref={(node: HTMLElement) => console.log("ref", node)}
        // style={chromeStyles}
        // defaultSubtitles

        style={{
          aspectRatio: `${width} / ${height}`,
          width: "100%",
          // opacity: isPlaying ? 1 : 0,
        }}
      >
        <mux-video
          playback-id={asset.playbackId}
          slot="media"
          crossorigin
          muted
          ref={videoPlayer}
          playsinline
        ></mux-video>
        <MediaPosterImage
          slot="poster"
          src={`https://image.mux.com/${asset.playbackId}/thumbnail.jpg`}
          placeholderSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8b2T0HwAF/wJkk6jtHAAAAABJRU5ErkJggg=="
        ></MediaPosterImage>

        <div
          class="center"
          slot="centered-chrome"
          style={{
            display: isPlaying ? "none" : "flex",
          }}
        >
          <MediaPlayButton>
            <span slot="play">
              <Play large />
            </span>
            <span slot="pause">
              <Pause large />
            </span>
          </MediaPlayButton>
        </div>

        <MediaControlBar
          style={{
            display: isPlaying ? "flex" : "none",
            transitionDuration: "0.5s",
          }}
        >
          <MediaPlayButton>
            <span slot="play">
              <Play />
            </span>
            <span slot="pause">
              <Pause />
            </span>
          </MediaPlayButton>

          <MediaTimeRange></MediaTimeRange>
          <MediaTimeDisplay showDuration></MediaTimeDisplay>
          <MediaFullscreenButton>
            <span slot="enter">
              <Fullscreen />
            </span>
            <span slot="exit">
              <ExitFullscreen />
            </span>
          </MediaFullscreenButton>

          <MediaMuteButton>
            <span slot="high">
              <Unmute />
            </span>
            <span slot="off">
              <Mute />
            </span>
          </MediaMuteButton>
        </MediaControlBar>
      </MediaController>
    </StyledMuxPlayerWithControls>
  );
};

const OldVideoWithControls = ({ data }) => {
  const [isPlaying, setPlaying] = React.useState(false);
  const [isMute, setMute] = React.useState(false);
  const [isFullscreen, setFullscreen] = React.useState(false);
  const [isOverlayVisible, setOverlayVisible] = React.useState(true);
  const playerRef = React.useRef(null);
  const stageRef = React.useRef(null);
  const overlayTimeoutRef = React.useRef(null);
  const [stageWidth, setStageWidth] = React.useState(null);

  const { asset } = data;
  if (!asset?.playbackId) return null;

  const { width, height } = getDimensions(asset?.data?.aspect_ratio);

  React.useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setFullscreen(true);
      } else {
        setFullscreen(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      // Clear the timeout when the component is unmounted
      if (overlayTimeoutRef.current) {
        clearTimeout(overlayTimeoutRef.current);
      }
    };
  }, []);

  const handlePlayPause = () => {
    const player = playerRef.current;

    if (player) {
      if (player.paused) {
        player.play();
      } else {
        player.pause();
      }
    }
  };

  const handleMouseMove = () => {
    // Clear the existing timeout
    if (overlayTimeoutRef.current) {
      clearTimeout(overlayTimeoutRef.current);
    }

    // Show the overlay
    setOverlayVisible(true);

    // Hide the overlay after 3 seconds of inactivity
    overlayTimeoutRef.current = setTimeout(() => {
      if (isPlaying) {
        setOverlayVisible(false);
      }
    }, 1000);
  };

  const handleFullscreen = () => {
    const stage = stageRef.current;
    const player = playerRef.current;

    // if (stage) {
    //   if (document.fullscreenElement) {
    //     document.exitFullscreen();
    //   } else {
    //     stage.requestFullscreen();
    //   }
    // }

    if (player && stage) {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          // for iOS Safari
          document.webkitExitFullscreen();
        }
      } else {
        if (player.requestFullscreen) {
          stage.requestFullscreen();
        } else if (player.webkitEnterFullscreen) {
          // for iOS Safari
          player.webkitEnterFullscreen();
        }
      }
    }
  };

  return (
    <div
      onMouseMove={handleMouseMove}
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        backgroundColor: isPlaying ? "transparent" : "var(--color--primary)",
        overflow: "hidden",
        lineHeight: 0,
        aspectRatio: `${width} / ${height}`,
      }}
    >
      <div ref={stageRef} style={{ width: "100%", height: "100%" }}>
        <Overlay isOverlayVisible={isOverlayVisible}>
          <Center>
            <Button large onClick={handlePlayPause}>
              {isPlaying ? <Pause /> : <Play />}
            </Button>
          </Center>
          <Bottom>
            <Button onClick={handleFullscreen}>
              {isFullscreen ? <ExitFullscreen /> : <Fullscreen />}
            </Button>

            <Button onClick={() => setMute(!isMute)}>
              {isMute ? <Unmute /> : <Mute />}
            </Button>
          </Bottom>
        </Overlay>

        <StyledMuxPlayerWithControls
          ref={playerRef}
          // autoPlay
          streamType="on-demand"
          nohotkeys
          // loop
          muted={isMute}
          onPlay={() => {
            setPlaying(true);
          }}
          onPause={() => {
            setPlaying(false);
          }}
          // poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8b2T0HwAF/wJkk6jtHAAAAABJRU5ErkJggg=="
          playbackId={asset.playbackId}
          style={{
            aspectRatio: `${width} / ${height}`,
            width: "100%",
          }}
        />
      </div>
    </div>
  );
  // )
};

export const VideoWithoutControls = ({ data }) => {
  const [isPlaying, setPlaying] = React.useState(false);

  const { asset } = data;
  if (!asset?.playbackId) return null;

  const { width, height } = getDimensions(asset?.data?.aspect_ratio);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        backgroundColor: isPlaying ? "transparent" : "var(--color--primary)",
        overflow: "hidden",
        lineHeight: 0,
        aspectRatio: `${width} / ${height}`,
      }}
    >
      <StyledMuxPlayer
        autoPlay
        streamType="on-demand"
        nohotkeys
        loop
        muted
        poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8b2T0HwAF/wJkk6jtHAAAAABJRU5ErkJggg=="
        playbackId={asset.playbackId}
        style={{
          aspectRatio: `${width} / ${height}`,
          width: "100%",
          opacity: isPlaying ? 1 : 0,
        }}
        onPlaying={() => {
          if (isPlaying) return;
          setPlaying(true);
        }}
      />
    </div>
  );
};

const Play = ({ large }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 25 25"
    className={large ? "large" : ""}
  >
    <path d="M21.3 11.9 4.7 1.4c-.3-.2-.6-.2-.9 0-.3.1-.4.4-.4.7v21.2c0 .3.2.6.4.7.1.1.3.1.4.1.2 0 .3 0 .5-.1l16.6-10.6c.2-.2.4-.4.4-.7s-.2-.6-.4-.8z" />
  </svg>
);

const Pause = ({ large }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 25 25"
    className={large ? "large" : ""}
  >
    <path d="M9.3 1h-5c-.5 0-.9.4-.9.9v21.2c0 .5.4.9.8.9h5.1c.5 0 .8-.4.8-.9V1.9c.1-.5-.3-.9-.8-.9zM20.7 1h-5.1c-.5 0-.8.4-.8.9v21.2c0 .5.4.9.8.9h5.1c.5 0 .8-.4.8-.9V1.9c.1-.5-.3-.9-.8-.9z" />
  </svg>
);

const Mute = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 25 25"
  >
    <path d="M26.8 1.4 25.7.3l-5.2 5.2V.9c0-.3-.2-.6-.5-.7-.3-.1-.6-.1-.8.2l-6.4 6.4H6.7c-.4 0-.8.3-.8.8v10c0 .4.3.8.8.8h1.1l-5.3 5.3 1.1 1.1 6.3-6.3h2.9l6.4 6.4c.1.1.3.2.5.2.1 0 .2 0 .3-.1.3-.1.5-.4.5-.7V7.7l6.3-6.3zM7.5 16.7V8.3h5.7c.2 0 .4-.1.5-.2L19 2.8v4.3l-9.6 9.6H7.5zM19 22.2 13.7 17c-.1-.1-.3-.2-.5-.2h-1.7L19 9.3v12.9z" />
  </svg>
);

const Unmute = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 25 25"
  >
    <path d="M20 .2c-.3-.1-.6-.1-.8.2l-6.4 6.4H6.7c-.4 0-.8.3-.8.8v10c0 .4.3.8.8.8h6.1l6.4 6.4c.1.1.3.2.5.2.1 0 .2 0 .3-.1.3-.1.5-.4.5-.7V.9c0-.3-.2-.6-.5-.7zm-1 22L13.7 17c-.1-.1-.3-.2-.5-.2H7.5V8.3h5.7c.2 0 .4-.1.5-.2L19 2.8v19.4z" />
  </svg>
);

const Fullscreen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 30 25"
  >
    <path d="M3.3 8.9H1.6V2.4H9v1.7H3.3zM27.7 22.6h-7.4v-1.7H26v-4.8h1.7zM9 22.6H1.6v-6.5h1.7v4.8H9zM27.7 8.9H26V4.1h-5.7V2.4h7.4z" />
  </svg>
);

const ExitFullscreen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 30 25"
  >
    <path d="M22 22.6h-1.7v-6.5h7.4v1.7H22zM9 8.9H1.6V7.2h5.7V2.4H9zM27.7 8.9h-7.4V2.4H22v4.8h5.7zM9 22.6H7.3v-4.8H1.6v-1.7H9z" />
  </svg>
);

function getDimensions(aspectRatio) {
  const values = aspectRatio.split(":");

  return {
    width: values[0],
    height: values[1],
  };
}
