import * as React from "react";
import * as S from "./styles";

import { useGenerateSkewStyle } from "../../../hooks/useGenerateSkewStyle";

export const Button = ({ children, skew, ...props }) => {
  const { skewStyle, generateSkewStyle } = useGenerateSkewStyle();

  React.useEffect(() => {
    if (!skew) return;
    generateSkewStyle();
  }, [skew]);

  return (
    <S.Button style={skew ? skewStyle : null} {...props}>
      {children}
    </S.Button>
  );
};

export const PillButton = ({ children, ...props }) => {
  return <S.PillButton {...props}>{children}</S.PillButton>;
};

export const TextButton = ({ children, ...props }) => {
  return <S.TextButton {...props}>{children}</S.TextButton>;
};
